<template>
  <div>
    <titleDiv></titleDiv>
    <div class="center">
      <div class="heading">
        <h1 class="title">服务条款</h1>
        <p class="text">最后更新: 2020-06-08</p>
      </div>
      <div class="container">
        <h3>联系我们</h3>
        <p><a href="mailto:service@wenjie-inc.com">有任何问题可邮件联系我们 service@wenjie-inc.com</a></p>
      </div>
    </div>
    <footerDiv></footerDiv>
  </div>
</template>

<script>
  import titleDiv from '../index/title';
  import footerDiv from '../index/footer'

  export default {
    name: 'terms',
    components: {
      titleDiv,
      footerDiv
    }
  }
</script>

<style lang='scss' scoped>
* {
  padding: 0;
  margin: 0;
  // text-align: center;
}

.center {
  max-width: 1280px;
  margin: 0 auto;
}

.heading {
  text-align: center;
  padding: 3rem;
}

.title {
  line-height: 60px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: #234689;
  letter-spacing: 0;
}

.text {
  line-height: 26px;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  color: #6d7d93;
  letter-spacing: 0;
  font-size: 17px;
}

.container {
  padding: 1rem 3.5rem 3.5rem;
  color: #4a4a4a
}

.container p {
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
}

.container h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #4a4a4a
}

.container ul {
  box-sizing: border-box;
  margin: 1rem 0 1rem 2rem;
}
</style>
